import React, { useState, Fragment } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import preloader from '../images/preloader.svg'
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Features from "./features";
// import Adsense from "./adsense"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faExclamationTriangle, faDownload, faClipboard } from '@fortawesome/free-solid-svg-icons'
const axios = require('axios').default;

const LikeeDownloader = ({ title, description }) => {
    const { t } = useTranslation();
    const [inputUrl, setInputUrl] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [responseData, setResponseData] = useState({});
    const [tableCollaspe, setTableCollaspe] = useState("videos-collapse");
    const [tableCollaspeText, setTableCollaspeText] = useState("Show More");
    const [showVideosSection, setShowVideosSection] = useState(false);
    const [showNoVideoError, setShowNoVideoError] = useState(false);

    const handleInputChange = (e) => {
        const url = e.target.value;
        setInputUrl(url);
        getFile(url);
    }

    const downloadButton = () => {
        getFile(inputUrl);
    }

    const getFile = (url) => {
        setShowVideosSection(false);
        setShowNoVideoError(false);
        if (url !== "") {
            setShowLoader(true);
            axios.post("https://flydownloader.com/wp-json/aio-dl/video-data/",
                {
                    url: url
                })
                .then(function (response) {
                    setShowLoader(false);
                    setResponseData(response.data);
                    setShowVideosSection(response);
                })
                .catch(function (error) {
                    setShowLoader(false);
                    setShowNoVideoError(true);
                });
        }
    }

    const showHideVideos = () => {
        if (tableCollaspe === "") {
            setTableCollaspe("videos-collapse");
            setTableCollaspeText("Show More");
        }
        else {
            setTableCollaspe("");
            setTableCollaspeText("Show Less");
        }
    }

    const downloadVideo = (url) => {
        window.open(url, "_blank");
    }

    const pasteLink = () => {
        navigator.clipboard.readText()
            .then(text => {
                setInputUrl(text);
                getFile(text);
            })
            .catch(err => { });
    }

    return (
        <>
            <Row>
                <Col className="download-section text-center pt-5 pb-5">
                    <h1 className="mt-3">{title}</h1>
                    <p className="fs-5">{description}</p>
                    <Row className="justify-content-center">
                        <Col xs lg="5" md="9" className="p-3">
                            <InputGroup>
                                <Form.Control
                                    size="lg"
                                    placeholder={t("Enter video link here")}
                                    aria-label={t("Enter video link here")}
                                    aria-describedby="downloader"
                                    value={inputUrl}
                                    onChange={handleInputChange}
                                />
                                <div className="paste-link" onClick={pasteLink}><FontAwesomeIcon icon={faClipboard} /></div>
                                <Button variant="danger" id="button-download" onClick={downloadButton}>
                                    {t("Download")} &#8594;
                                </Button>
                            </InputGroup>
                            <p className="legal mt-1 mb-0"><strong>{t("Legal Disclaimer")}:</strong> {t("You must be the owner of the video's rights or have permission from its owner in order to use this tool")}.</p>
                            {showLoader &&
                                (<img src={preloader} alt="preloader"></img>)
                            }
                        </Col>
                    </Row>
                    {showNoVideoError && (
                        <Row className="justify-content-center mt-3">
                            <Col xs lg="8" md="9" className="text-yellow">
                                <FontAwesomeIcon style={{ fontSize: "60px" }} icon={faExclamationTriangle} />
                                <p className="mb-1">{t("No download link found")}!!</p>
                                <p>{t("Please make sure the video URL is correct and video or the account of the video is not private")}!</p>
                            </Col>
                        </Row>
                    )}
                    {/* <Row className="justify-content-center mt-2">
                        <Col className="text-center">
                            <Adsense slot="4259312234"/>
                        </Col>
                    </Row> */}
                    {showVideosSection && (
                        <Row className="justify-content-center mt-3">
                            <Col xs lg="8" md="9">
                                <Card className="shadow videos-card">
                                    <Card.Body className="download-section">
                                        <Row className="text-start">
                                            <Col lg="5" md="5" xs="12" className="order-last order-md-first">
                                                {responseData?.thumbnail && (
                                                    <>
                                                        <img src={responseData?.thumbnail} className="w-100 thumb-img" alt="thumbnail" />
                                                        <p className="my-2 text-center"><strong>{responseData?.title}</strong></p>
                                                        {responseData?.duration && (
                                                            <p>{t("Duration")}: {responseData?.duration}</p>
                                                        )}
                                                    </>
                                                )}
                                            </Col>
                                            <Col>
                                                <Tabs
                                                    defaultActiveKey="videos"
                                                    id="download-files"
                                                    fill
                                                >
                                                    <Tab eventKey="videos" title={<Fragment><FontAwesomeIcon icon={faPlayCircle} /> {t("Videos")}</Fragment>}>
                                                        <Table bordered style={{ color: "white" }} className={`videos-table ${tableCollaspe}`}>
                                                            <tbody>
                                                                {responseData?.medias?.map((video) => (
                                                                    <tr key={video?.quality + '-' + video?.extension}>
                                                                        <td>
                                                                            <span className="float-start pt-1-5"><FontAwesomeIcon className="text-yellow" icon={faPlayCircle} /> <strong>{video?.quality}</strong>.{video?.extension}</span>
                                                                            <span className="float-end"><Button variant="danger" onClick={() => downloadVideo(video?.url)} type="button"><FontAwesomeIcon icon={faDownload} /> {t("Download")}</Button></span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                {responseData?.medias && responseData?.medias?.length > 3 && (
                                                                    <tr style={{ display: "table-row", cursor: "pointer" }}>
                                                                        <td className="text-center" style={{ cursor: "pointer" }} onClick={showHideVideos}>{tableCollaspeText}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Features title={title} />
        </>
    );
}

export default LikeeDownloader
